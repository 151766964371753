import React from "react"

// Styled components
import { Logo, NavBar, NavLink } from "./jumpNav.styles"

const JumpNav = data => {
  return data.sections &&
    data.sections.filter(section => section.heading).length > 1 ? (
    <NavBar>
      {data.sections.map(section =>
        section.heading ? (
          <NavLink href={`#${section.heading.replace(" ", "-").toLowerCase()}`}>
            {section.heading}
          </NavLink>
        ) : null
      )}
    </NavBar>
  ) : null
}

export default JumpNav
