import styled from "@emotion/styled"
import { Gloria } from "../../globalCss"

export const ContentBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Title = styled.h1`
  ${Gloria}
  color: white;
  font-size: 5rem;
`
