import React from "react"
import { graphql } from "gatsby"

// My components
import BackgroundImage from "../../components/backgroundImage"
import SEO from "../../components/seo"
import Section from "../../components/section"
import JumpNav from "../../components/jumpNav"
import { Title, ContentBox } from "./standardPage.styles"

export default ({ data }) => {
  const { title, image, Section: sections } = data.markdownRemark.frontmatter
  return (
    <>
      <SEO
        title={title}
        keywords={[
          `st john's`,
          `methodist`,
          `church`,
          "bloxwich",
          "walsall",
          "wolverhampton",
          "christianity",
          "easter",
        ]}
      />
      <BackgroundImage src={image} alt={`${title}-banner`}>
        <ContentBox>
          <Title>{title}</Title>
        </ContentBox>
      </BackgroundImage>
      <JumpNav sections={sections} />
      {sections &&
        sections.map(section => (
          <Section heading={section.heading} body={section.body} />
        ))}
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        subtitle
        image
        Section {
          body
          heading
        }
      }
    }
  }
`
