import styled from "@emotion/styled"
import {
  logoRed,
  darkGrey,
  offWhite,
  logoOrange,
  Gloria,
} from "../../globalCss"

export const NavBar = styled.div`
  position: sticky;
  top: 6.2rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  background-color: ${offWhite};
  padding: 0.5rem;
  justify-content: flex-start;
  align-items: center;
  line-height: 1;
  max-width: 1200px;
  margin: 0 auto;
  border: ${darkGrey} 1px solid;
  box-shadow: 0px 0px 0px 0px rgba(82, 82, 82, 0.76);
  z-index: 0;

  @media only screen and (max-width: 800px) {
    display: none;
  }
`

export const NavLink = styled.a`
  ${Gloria}
  font-size: 2rem;
  color: ${logoOrange};
  text-decoration: none;
  margin: 0 2rem;
`
